@use '~/styles' as *;

.sider {
  height: calc(100vh - 64px);

  @include desktop {
    margin-right: 32px;
  }

  @include mobile {
    margin-right: 20px;
  }

  @include tablet {
    margin-right: 20px;
  }
}

.sider-menu {
  font-weight: 600;
  color: $textColor;
}

.icon {
  position: absolute;
  top: 50%;
  inset-inline-end: 16px;
  width: 16px;
  color: currentcolor;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
}
