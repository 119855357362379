@use '~/styles' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  line-height: normal;
  background-color: $companyBgColor;
}

.logo {
  width: 200px;

  img {
    height: 100%;
  }
}

.user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-avatar {
  margin-right: 30px;
  background: rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  color: $textColor;

  * {
    line-height: normal;
  }

  a {
    color: $textColor;
    text-align: end;
  }
}

.system-color {
  background-color: $systemBgColor;
}

.dropdown {
  min-width: 240px !important;
}

.logout-button {
  width: 70px;
  border-radius: 2px;
  padding: 4px 12px;
}

.text-decoration {
  text-decoration: underline;
}
