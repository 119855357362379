.display {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 200;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.display-none {
  display: none;
}
