@font-face {
  font-family: 'HiraginoSans-W3';
  src: url('assets/fonts/HiraginoUDSansStd-W3.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'HiraginoSans-W4';
  src: url('assets/fonts/HiraginoUDSansStd-W4.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'HiraginoSans-W5';
  src: url('assets/fonts/HiraginoUDSansStd-W5.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'HiraginoSans-W6';
  src: url('assets/fonts/HiraginoUDSansStd-W6.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'Hiragino Sans';
  src: url('assets/fonts/HiraginoUDSansStd-W3.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'Hiragino Sans';
  src: url('assets/fonts/HiraginoUDSansStd-W4.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'Hiragino Sans';
  src: url('assets/fonts/HiraginoUDSansStd-W5.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  line-height: 1.5;
}

@font-face {
  font-family: 'Hiragino Sans';
  src: url('assets/fonts/HiraginoUDSansStd-W6.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
}

* {
  font-family: 'Hiragino Sans', 'HiraginoSans-W3', 'HiraginoSans-W4',
    'HiraginoSans-W5', 'HiraginoSans-W6', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Hiragino Sans', 'HiraginoSans-W3', 'HiraginoSans-W4',
    'HiraginoSans-W5', 'HiraginoSans-W6', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  width: 100%;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4096ff;
  background-color: #4096ff;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #4096ff;
  background-color: #4096ff;
}

.ant-menu-item-selected {
  border-radius: unset !important;
  border-right: 3px solid #7e6dd6;
}

.ant-menu-item-active {
  border-radius: unset !important;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #333333 !important;
}
.ant-menu-submenu > .ant-menu-submenu-title {
  border-radius: unset !important;
}

.form-checkbox {
  margin-bottom: 16px;
}

.form-checkbox > .ant-form-item-row > .ant-form-item-control {
  margin: auto;
}
.form-checkbox
  > .ant-form-item-row
  > .ant-form-item-control
  > .ant-form-item-control-input {
  margin: auto;
  opacity: 1;
  color: rgba(0, 0, 0, 0.65);
}

.form-button > .ant-form-item-row > .ant-form-item-control {
  margin: auto;
}
.form-button
  > .ant-form-item-row
  > .ant-form-item-control
  > .ant-form-item-control-input {
  margin: auto;
  opacity: 1;
  color: rgba(0, 0, 0, 0.65);
}

.ant-layout-content {
  min-height: unset;
}

.ant-layout {
  min-height: unset;
}

.sleep-item {
  display: flex;
  border-radius: 10px;
  opacity: 1;
  background-color: #ededed;
  padding: 12px 32px;
  align-items: center;
  justify-content: center;
}

.sleep-item-info {
  color: #7e6dd6;
  cursor: pointer;
}

.sleep-item-first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 10px;
}
