@use './variables' as *;

:global {
  .ant-table-filter-dropdown {
    width: 270px !important;
  }
  .ant-pagination-item,
  .ant-pagination-next,
  .ant-pagination-prev {
    border-radius: 2px;
    border: 1px solid $paginationBorder !important;
    opacity: 1;
    background-color: $paginationBg !important;
    opacity: 1;
    a {
      color: $paginationTextColor !important;
    }
  }
  .ant-pagination-item-active {
    background-color: $paginationBgActive !important;
    a {
      color: $paginationTextColorActive !important;
    }
  }
  .option > .ant-select-item-option-content {
    color: $textLinkColor;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border: 1px solid $companyBgColor;
    border-bottom-color: $white;
    border-top: 3px solid $companyBgColor;
    color: $textColor;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: none;
    border: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid $companyBgColor;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: unset;
  }
  .ant-tabs-tab {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .modal {
    .ant-modal-content,
    .confirm-button,
    .cancel-button {
      border-radius: 2px;
    }
    .ant-modal-confirm-title {
      font-size: 16px;
      font-weight: 400 !important;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
    .ant-modal-confirm-content {
      font-size: 14px !important;
      color: $paginationTextColor !important;
    }
  }

  .ant-picker-dropdown .ant-picker-header-view button:not(:first-child) {
    margin-inline-start: 0;
  }

  .flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .align-start {
    align-items: flex-start;
  }

  .align-end {
    align-items: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-around {
    justify-content: space-around;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  $margin-values: 0 5px 10px 20px 30px;

  @each $value in $margin-values {
    .m-#{$value} {
      margin: $value;
    }
    .mt-#{$value} {
      margin-top: $value;
    }
    .mb-#{$value} {
      margin-bottom: $value;
    }
    .ml-#{$value} {
      margin-left: $value;
    }
    .mr-#{$value} {
      margin-right: $value;
    }
  }

  $padding-values: 0 5px 10px 20px 30px;

  @each $value in $padding-values {
    .p-#{$value} {
      padding: $value;
    }
    .pt-#{$value} {
      padding-top: $value;
    }
    .pb-#{$value} {
      padding-bottom: $value;
    }
    .pl-#{$value} {
      padding-left: $value;
    }
    .pr-#{$value} {
      padding-right: $value;
    }
  }

  .flex-1 {
    flex: 1;
  }

  .w-100 {
    width: 100%;
  }

  .w-100p {
    width: 100% !important;
  }

  .width-200 {
    width: 200px;
  }

  .width-full {
    width: 100%;
  }

  .ant-table-cell span {
    white-space: nowrap;
    word-break: unset;
  }

  .fw-7 {
    font-weight: 700;
  }
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.invited-color {
  color: $invitedColor;
}

.deleted-color {
  color: $deletedColor;
}

.withdrawal-color {
  color: $withdrawalColor;
}

.notification-type {
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 12px;
  color: $invitedColor;
  border: 1px solid $invitedColor;
  text-align: center;

  &.all {
    background-color: $invitedColor;
    color: $white;
  }
}

.notification-major {
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 12px;
  color: $white;
  background: $deletedColor;
  text-align: center;
}

.hidden {
  display: none;
}
