@use '~/styles' as *;

.title {
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.form-item {
  margin-bottom: 0 !important;
}

.text-upload {
  text-decoration: underline !important;
  color: $textUpload !important;
  cursor: pointer;
}

.text-remove {
  color: $removeText !important;
  cursor: pointer;
}