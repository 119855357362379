@use './variables' as *;

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: ($mobile-width + 1)) and (max-width: $tablet-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: ($tablet-width + 1)) {
    @content;
  }
}
