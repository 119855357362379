$textLinkColor: #7e6dd6;
$textColor: #333333;
$borderColor: #7e6dd6;
$mobile-width: 576px;
$tablet-width: 1024px;
$borderColor: #7e6dd6;
$bgColor: #f7f7f7;
$grayBorder: #d8d8d8;
$gray: #f7f7f7;
$gray2: #fdfdfd;
$companyBgColor: #e5d9ea;
$systemBgColor: #95aae2;
$grayCopy: #6E6E6E;
$iconSearchColor: rgba(0, 0, 0, 0.25);
$invitedColor: #ff9823;
$deletedColor: #f15376;
$withdrawalColor: #999999;
$paginationBorder: rgba(0, 0, 0, 0.15);
$paginationBg: rgba(255, 255, 255, 1);
$paginationTextColor: rgba(0, 0, 0, 0.65);
$paginationBgActive: #1890ff;
$paginationTextColorActive: #ffffff;
$borderExpandIconColor: rgba(126, 109, 214, 1);
$bgExpandIconColor: rgba(247, 247, 247, 1);
$white: #fff;
$textAgeColor: #00000025;
$textParagraphColor: rgba(51, 51, 51, 1);
$textUpload: #007BFF;
$removeText: #FF4D4F;
$textResend: #7678F8;
$btnTypeColor: #ededed;
