@use '~/styles' as *;

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 64px;
}

.wrapper {
  background-color: $bgColor;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}
